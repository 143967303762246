module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"","short_name":"","description":"","start_url":"","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"src/img/icons/innoway.jpeg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/services/*","/products/*","/aboutus/*","/contactus/*"]},
    }]
